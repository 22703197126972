import { Fragment } from 'react';

import { Div } from '@playbooks/interface/html';
import { Skeleton } from 'molecules/skeletons';
import { listBuilder } from 'utils';

const ThumbnailList = ({ type = 'thumbnail', data = [], count = 4, loading, renderItem, tailwind }) => {
	const props = { loading, tailwind };
	const RenderItem = props => renderItem(props);
	// Render
	return (
		<Fragment>
			{loading ? (
				<Fragment>
					{listBuilder(count).map((v, i) => (
						<Skeleton type={type} tailwind={tailwind} />
					))}
				</Fragment>
			) : (
				<Fragment>
					{data.length > 0 && (
						<Div display='flex-wrap' space='space-x-2 space-x-reverse'>
							{data?.map((item, i) => <RenderItem item={item} className={i === 0 ? 'mr-2 mb-2' : 'mb-2'} {...props} />)}
						</Div>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

export { ThumbnailList };

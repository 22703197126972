import { Feedback, FeedbackActions, FeedbackBody, FeedbackIcon, FeedbackText } from '@playbooks/interface/feedbacks';

const PreviewFeedback = ({ icon, text, children, tailwind }) => {
	// Render
	return (
		<Feedback align='text-center' spacing='p-4' {...tailwind?.feedback}>
			<FeedbackBody>
				<FeedbackIcon icon={icon} {...tailwind?.icon} />
				<FeedbackText>{text}</FeedbackText>
				{children && <FeedbackActions display='flex-middle'>{children}</FeedbackActions>}
			</FeedbackBody>
		</Feedback>
	);
};

export { PreviewFeedback };

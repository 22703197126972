import {
	Feedback,
	FeedbackBody,
	FeedbackHeader,
	FeedbackIcon,
	FeedbackText,
	FeedbackTitle,
} from '@playbooks/interface/feedbacks';

const DisplayFeedback = ({ icon, title, text, tailwind }) => {
	// Render
	return (
		<Feedback align='text-center' spacing='p-8' {...tailwind?.feedback}>
			<FeedbackHeader {...tailwind?.header}>
				<FeedbackIcon icon={icon} fontSize='text-3xl' {...tailwind?.icon} />
			</FeedbackHeader>
			<FeedbackBody>
				<FeedbackTitle>{title}</FeedbackTitle>
				<FeedbackText>{text}</FeedbackText>
			</FeedbackBody>
		</Feedback>
	);
};

export { DisplayFeedback };
